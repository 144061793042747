@import "../../styles/constants";
@import "../../styles/breakpoints";

$modal-padding-h: 19px;
$modal-section-title-bk:  #E0E0E0;

.modal-container {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 1000;
  color: $bk-dark;

  .modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    //border: 1px solid #979797;
    border-radius: 4px;
    background: white;
    width: 500px;
    max-width: calc(100vw - 20px);

    .modal-header {
      padding: 22px $modal-padding-h;
      display: flex;
      font-weight: 500;
      font-size: 20px;
      align-items: center;

      .badge {
        display: inline-block;
        background: #BD4E2B;
        border-radius: 2px;
        line-height: 20px;
        padding: 0px 4px;
        margin-left: 8px;
        font-weight: 500;
        font-size: 12px;
        color: #E2E2E2;
      }

      .close-btn {
        margin-left: auto;
        cursor: pointer;
        width: 40px;
        line-height: 40px;
        text-align: center;

        &:hover {
          background: rgba(196, 196, 196, 0.397);
          border-radius: 2px;
        }
      }
    }

    .modal-section-title {
      font-weight: 600;
      font-size: 12px;
      color: #4D4F51;
      padding-left: $modal-padding-h;
      padding-right: $modal-padding-h;
      padding-top: 24px;
      padding-bottom: 16px;
      text-transform: uppercase;
      background: $modal-section-title-bk;
      display: flex;

      .modal-section-title_badge {
        margin-left: auto;
        font-weight: 500;
      }
    }

    .list-item {
      padding: 10px $modal-padding-h;
      font-size: 16px;
      display: block;
    }
  }
}
