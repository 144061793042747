$bk-mid: #1b1e24;
$bk-dark: #161619;
$bk-light: #2A3339;
$bk-darkest: #0E0E10;

$header-height: 60px;
$toolbar-height: 55px;

$grid-gap: 2px;






$bk-grey: #2a3135;
$grey-light: #efefef;
$grey-medium: #9b9b9b;
$grey-dark: #57595b;
$yellow :#ffb602;
$green: #009245;

$red: #ef1126;
$black-dark: rgb(28, 33, 37);
$text-black: #4a4a4a;
$orange: #ff7f6e;

$border-radius: 3px;
$desktop-padding: 28px;