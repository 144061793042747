.olark-survey-form-item-name,.olark-survey-form-item-email {

  svg {
    vertical-align: top;
  }
}

@media screen and (min-width: 1100px){
  .olark-size-md .olark-survey-form-item-textarea svg{
    margin-top: 12px;
  }
}
